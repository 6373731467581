import React from "react";
import {graphql, Link, useStaticQuery} from "gatsby";

interface IProps {
    location: Location;
    title: string;
    children: any;
}

declare var __PATH_PREFIX__: string;

const Layout = ({location, title, children}: IProps) => {
    const rootPath = `${__PATH_PREFIX__}/`;
    const isRootPath = location.pathname === rootPath;
    let header;

    if (isRootPath) {
        header = (
            <h1 className="main-heading">
                <Link to="/">{title}</Link>
            </h1>
        );
    } else {
        header = (
            <Link className="header-link-home" to="/">
                {title}
            </Link>
        );
    }

    const data = useStaticQuery(graphql`
        query FooterQuery {
            site {
                siteMetadata {
                    social {
                        twitter
                    }
                }
            }
        }
    `);
    const social = data.site.siteMetadata?.social;

    return (
        <div className="global-wrapper" data-is-root-path={isRootPath}>
            <header className="global-header">{header}</header>
            <main>{children}</main>
            <footer>
                <div className="footer">
                    You may like to check my social media:
                    <li>
                        <a target="_blank" href="https://www.facebook.com/PragmaticReview">
                            PragmaticReview's Facebook
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href={`https://twitter.com/${social?.twitter || ``}`}>
                            Twitter
                        </a>
                    </li>
                </div>
            </footer>
        </div>
    );
};

export default Layout;
